<template>
  <div>
    <div class="login-page">
      <v-container>
        <v-row justify="center" class="mt-16">
          <v-col cols="12" lg="5">
            <v-card flat class="rounded-lg mb-5">
              <form
                @submit.prevent="findMyAccount"
                ref="formPhone"
                class="mb-5"
                v-if="!user"
              >
                <v-card-text class="pa-6 pa-lg-12">
                  <h3 class="text-center mb-lg-8 mb-4">Trouvez votre compte</h3>
                  <p class="text-center">
                    Veuillez entrer votre numéro de mobile pour rechercher votre
                    compte.
                  </p>

                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-phone"
                    type="text"
                    v-model="form.number"
                    label="Numéro mobile *"
                    :color="
                      errorsFindPhone && errorsFindPhone.number
                        ? 'red'
                        : 'primary'
                    "
                    :class="
                      errorsFindPhone && errorsFindPhone.number
                        ? 'redBorder'
                        : ''
                    "
                    @input="
                      errorsFindPhone && errorsFindPhone.number
                        ? (errorsFindPhone.number = '')
                        : false
                    "
                    hide-details=""
                  >
                  </v-text-field>

                  <p class="red--text mt-0">
                    {{
                      errorsFindPhone.number ? errorsFindPhone.number[0] : ""
                    }}
                  </p>

                  <v-btn
                    type="submit"
                    depressed
                    :loading="findPhoneLoading"
                    large
                    block
                    color="primary"
                    class="rounded-lg"
                  >
                    Rechercher
                  </v-btn>
                </v-card-text>
              </form>

              <form
                @submit.prevent="sendCode"
                ref="confirmAccount"
                class="mb-5"
                v-if="user && !sms_sent"
              >
                <v-card-text class="pa-6 pa-lg-12 pb-0">
                  <h3 class="text-center">
                    Nous vous enverrons un code à votre Téléphone
                  </h3>
                  <v-list three-line>
                    <template>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img :src="getImageLink(user.image)"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ user.name }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ user.phone }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>

                  <div
                    id="recaptcha-container"
                    :data-sitekey="getSiteKey"
                    data-callback="sendForm"
                    data-size="invisible"
                  ></div>
                </v-card-text>
                <v-card-actions class="flex-column pa-6 pt-0">
                  <v-btn
                    type="submit"
                    depressed
                    :loading="sendCodeLoading"
                    large
                    block
                    color="primary"
                    class="rounded-lg"
                  >
                    Continuer
                  </v-btn>

                  <v-btn
                    @click="user = null"
                    type="submit"
                    depressed
                    large
                    block
                    color="default"
                    class="rounded-lg mt-2"
                  >
                    Annuler
                  </v-btn>
                </v-card-actions>
              </form>

              <form
                v-if="user && sms_sent && !phoneConfirmed"
                :disabled="checkLoading"
              >
                <v-card-text class="pa-6 pa-lg-12 justify-center">
                  <h3 class="text-center mb-lg-8 mb-4">
                    Confirmation de votre Telephone
                  </h3>
                  <p class="d-flex justify-center">
                    <OtpInput
                      ref="otpInput"
                      input-classes="otp-input"
                      separator="-"
                      :num-inputs="6"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-change="handleOnChange"
                      @on-complete="handleOnComplete"
                    />
                  </p>
                  <!-- <p class="red--text d-flex justify-center">
                    {{ errors.phone ? errors.phone[0] : "" }}
                  </p> -->
                  <div class="d-flex justify-center">
                    <p class="red--text justify-center" v-if="code_error">
                      Code Incorrect <br />
                      <v-btn
                        @click="sms_sent = false"
                        text
                        color="gifty"
                        class="text-decoration-none mt-3"
                      >
                        Réenvoyer
                      </v-btn>
                    </p>
                  </div>

                  <p class="text-center">
                    <v-progress-circular
                      color="primary"
                      indeterminate
                      v-if="checkLoading"
                    >
                    </v-progress-circular>
                  </p>
                </v-card-text>
              </form>

              <!-- <v-card-text v-if="phoneConfirmed">
                <p class="text-center" v-if="loginLoading">Loading ...</p>
                <p v-if="loginErrors">
                  {{ loginErrors }}
                </p>
              </v-card-text> -->

              <form
                v-if="user && sms_sent && phoneConfirmed"
                @submit.prevent="updatePassword"
              >
                <v-card-text class="pa-6 pa-lg-12">
                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-lock-outline"
                    type="password"
                    v-model="form.password"
                    label="Nouveau Mot de passe *"
                    :color="
                      errorsPassword && errorsPassword.password
                        ? 'red'
                        : 'primary'
                    "
                    :class="
                      errorsPassword && errorsPassword.password
                        ? 'redBorder'
                        : ''
                    "
                    @input="
                      errorsPassword && errorsPassword.password
                        ? (errorsPassword.password = '')
                        : false
                    "
                    hide-details=""
                  >
                  </v-text-field>

                  <p class="red--text mt-0">
                    {{
                      errorsPassword.password ? errorsPassword.password[0] : ""
                    }}
                  </p>

                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-lock-outline"
                    type="password"
                    v-model="form.password_confirmation"
                    label="Confirmation Mot de passe *"
                    :color="
                      errorsPassword && errorsPassword.password_confirmation
                        ? 'red'
                        : 'primary'
                    "
                    :class="
                      errorsPassword && errorsPassword.password_confirmation
                        ? 'redBorder'
                        : ''
                    "
                    @input="
                      errorsPassword && errorsPassword.password_confirmation
                        ? (errorsPassword.password_confirmation = '')
                        : false
                    "
                    hide-details=""
                  >
                  </v-text-field>
                  <p class="red--text mt-0">
                    {{
                      errorsPassword.password_confirmation
                        ? errorsPassword.password_confirmation[0]
                        : ""
                    }}
                  </p>

                  <v-btn
                    type="submit"
                    depressed
                    :loading="updatePasswordLoading"
                    large
                    block
                    color="primary"
                    class="rounded-lg"
                  >
                    Enregister
                  </v-btn>
                </v-card-text>
              </form>
            </v-card>
            <p class="text-center white--text mt-10">
              © Copyright {{ new Date().getFullYear() }}
              <strong class="text-primary">Gifty</strong> Tous droits réservés.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";

// FireBase
import OtpInput from "@bachdgvn/vue-otp-input";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  RecaptchaVerifier,
  GoogleAuthProvider,
  signInWithPhoneNumber,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId,
};

var app;
var provider;
var auth;

export default {
  components: {
    OtpInput,
  },
  data() {
    return {
      findPhoneLoading: false,
      sendCodeLoading: false,
      errorsFindPhone: {},
      form: {
        number: "",
        password: "",
        password_confirmation: "",
        token: "",
      },
      user: null,

      sms_sent: false,
      checkLoading: false,
      sendError: false,
      code_error: false,
      phoneConfirmed: false,

      updatePasswordLoading: false,
      errorsPassword: {},
    };
  },

  methods: {
    findMyAccount() {
      this.findPhoneLoading = true;
      axios
        .post(process.env.VUE_APP_BASE_URL + "v1/find_user_by_phone", this.form)
        .then((res) => {
          this.findPhoneLoading = false;
          this.user = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.findPhoneLoading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errorsFindPhone = err.response.data.errors;
          }
        });
    },

    sendCode() {
      this.initilizeCaptcha();
    },

    // Firebase
    getSiteKey() {
      return process.env.VUE_APP_recaptcha_sitekey;
    },

    initilizeCaptcha() {
      try {
        // Initialize Firebase
        // Initialize Firebase
        app = initializeApp(firebaseConfig);
        provider = new GoogleAuthProvider();

        //window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
        auth = getAuth(app);
      } catch (e) {
        console.log(e);
      }
      this.sendCodeLoading = true;
      this.sendError = null;
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            //console.log(response);
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        },
        auth
      );

      const appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(auth, this.user.phone, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          this.sendCodeLoading = false;

          this.sms_sent = true;
          this.code_error = false;
        })
        .catch((error) => {
          this.sendCodeLoading = false;

          if (
            error == "FirebaseError: Firebase: Error (auth/too-many-requests)."
          ) {
            this.sendError =
              "Trop de tentatives, veuillez réessayer ultérieurement ";
          }
        });
    },

    checkCode(code) {
      this.checkLoading = true;
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          // ...
          console.log(user);
          this.checkLoading = false;
          this.code_error = false;
          this.phoneConfirmed = true;
          this.form.token = result.user.accessToken;
          //this.afterPhoneConfirmed(result.user);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          this.checkLoading = false;
          this.code_error = true;
        });
    },

    // Otp Input
    handleOnComplete(value) {
      //console.log("OTP completed: ", value);
      this.checkCode(value);
    },
    handleOnChange(value) {
      //console.log("OTP changed: ", value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },

    // afterPhoneConfirmed
    updatePassword(result) {
      this.updatePasswordLoading = true;
      this.errorsPassword = {};

      axios
        .post(process.env.VUE_APP_BASE_URL + "v1/login_firebase_token", {
          user_id: this.user.id,
          token: this.form.token,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
        })
        .then((res) => {
          this.updatePasswordLoading = false;
          console.log(res.data);

          this.$store.dispatch("isLoggedIn", true);
          this.$store.dispatch("user", res.data);
          this.$store.dispatch("stores", []);
          this.$router.go(0);
        })
        .catch((err) => {
          console.log(err);
          this.updatePasswordLoading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errorsPassword = err.response.data.errors;
          }
        });
    },
  },
  created() {},
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  color: rgba(214, 1, 72);
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(214, 1, 72, 0.993);
  text-align: center;
  outline-color: rgba(214, 1, 72);
}

.otp-input:focus-visible {
  outline-color: rgba(214, 1, 72);
  border: 1px solid rgba(214, 1, 72, 0.993);
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.redBorder >>> fieldset {
  border-color: rgba(250, 0, 33, 0.986);
}
</style>
  